import React from "react";
import { useParams } from "react-router";
import { useGetMmPatientLazyQuery } from "../../generated/graphql";

function SearchByPID(props) {
  let textInput = React.createRef();
  const { token } = useParams();

  function renderAutofillBtn() {
    if (token === undefined) {
      return (
        <div
          style={{ width: "25%", cursor: "pointer" }}
          className="btn btn-primary width20 float-right"
          onClick={callPatientQuery}
        >
          Autofill
        </div>
      );
    }
  }

  const [patientQuery] = useGetMmPatientLazyQuery({
    onCompleted: (data) => {
      props.setPatientById(data.getMMPatient);
    },
  });

  const callPatientQuery = () => {
    const re = /^[0-9\b]+$/;
    if (re.test(textInput.current.value)) {
      patientQuery({
        variables: { patientid: parseInt(textInput.current.value) },
      });
    } else {
      textInput.current.value.slice(0, -1);
    }
  };

  return (
    <div>
      <input
        style={{ width: "70%", float: "left" }}
        className="form-control width-80"
        ref={textInput}
        type="text"
        onChange={(e) => props.setMM_Patient_Number(e.target.value)}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
      />
      {renderAutofillBtn()}
    </div>
  );
}

export default SearchByPID;
