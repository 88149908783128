import React, { CSSProperties } from 'react';

const Input: React.FC<{styles?: CSSProperties} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>> = ({ className, styles, ...props}) => (
	<input
		className={`form-control ${className}`}
		style={styles}
		{...props}
	/>
);

export default  Input