import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { successNotification } from "../../redux/actions";

export default function SuccessNotfication() {
    const dispatch = useDispatch()
    const _setTimeout = useRef(null)
    const isActive = useSelector(store => store?.notification?.isActive);
    const message = useSelector(store => store?.notification?.message);
    useEffect(() => {
        if (isActive) {
            clearTimeout(_setTimeout.current)
            _setTimeout.current = setTimeout(() => {
                dispatch(successNotification(false, ""))
            }, 3000)
        }
        return () => {
            clearTimeout(_setTimeout.current)
        }
    }, [isActive, dispatch])
    return (
        <div className="alert alert-success success-message " role="alert">
            {message}
        </div>
    )
}