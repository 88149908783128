import React, { useState } from "react";
import { connect } from "react-redux";
import { handleInputChange, setKeyAction } from "../redux/actions";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import { useParams, useLocation } from "react-router";
import DCSignature from "../components/PatientRegistration/consent_signature";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  sectionHead: {
    backgroundColor: "#42b0f5",
    color: "white",
  },
  fieldContainer: {
    marginTop: "-8px",
    backgroundColor: "white",
    color: "black",
    padding: "10px 5px",
    borderColor: "#42d7f5",
    borderStyle: "solid",
  },
}));

function SignatureRecaptureIL({
  history,
  handleInputChange,
  signUrl,
  consentChecked,
  statementChecked,
  medosyncChecked,
}) {
  const location = useLocation();
  const isSigPad = location.pathname.startsWith("/sig/recapture");
  const isILH = location.pathname.startsWith("/recaptureIL");
  const { pid, token } = useParams();
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const maybePid = pid || ``;
  const maybeToken = token || ``;
  const isHospitalName = process.env.REACT_APP_USE_HOSPITAL_NAME.toLowerCase();

  return (
    <div className="container mt-5">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!signUrl && !isSigPad) {
            setErrors({ signUrl: "required" });
            return;
          }
          if (isSigPad) {
            history.push(`/sig/getSignature/${maybePid}`);
          }

          if (isILH) {
            history.push(`/recaptureconfirm/${maybePid}/${maybeToken}`);
          }
        }}
      >
        <div style={{ marginBottom: 10, marginTop: 20 }}> </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className={classes.sectionHead}>
            <h6 className="p-2">Consent and Declaration</h6>

            <div className={classes.fieldContainer}>
              <h5>Consent</h5>
              <p>
                I declare that at the time I underwent medical treatment I was a
                party to a health insurance contract and was entitled to
                treatment under my Irish Life Health plan. I declare that to the
                best of my knowledge, the information provided in Part 1 of this
                form is accurate, true and complete. I authorise the doctors or
                minor injury clinic to furnish Irish Life Health, or any
                authorised agent it may appoint to act on its behalf, with any
                information requested, including access to my medical records,
                where this is necessary in relation to treatment or services
                received by me or my named dependants in respect of this claim.
                I understand that only medical information relating to my claim
                will be requested by Irish Life Health. I authorise the direct
                payment by Irish Life Health to the doctors/minor injury clinic
                for the services set out on this claim form to the extent
                provided for under my Irish Life Health plan. I verify the
                details of the accounts submitted on my behalf by the
                doctor/hospital/consultant as an accurate reflection of the
                treatment I received. I understand that the details of these
                amounts will be included in my Irish Life Health statement of
                payment and I will have the opportunity to contact Irish Life
                Health directly with any queries. Charges not covered under the
                Irish Life Health plan to which I subscribe will remain my
                responsibility or that of the named dependant who received the
                treatment to settle directly with the minor injury clinic
                concerned.{" "}
              </p>
              <div>
                <Checkbox
                  checked={!!statementChecked}
                  color="primary"
                  required={true}
                  onChange={(e) =>
                    handleInputChange(
                      "confirmInfo",
                      "statementChecked",
                      e.target.checked ? "yes" : "no"
                    )
                  }
                />
                I Agree
              </div>

              <div className="my-3"></div>
              <h5 htmlFor="consent">Declaration</h5>
              <p>
                I/we confirm that all the details, answers and information given
                in this form are true, accurate and complete. I/we confirm that
                I/ we am/are giving my/our permission to you to use the
                information I/we have given on this form for the purposes set
                out in the Irish Life Health Privacy Notice which can be found
                at
                <a
                  href={
                    "http://www.irishlifehealth.ie/privacy-and-legal/privacy-statement"
                  }
                >
                  {" "}
                  http://www.irishlifehealth.ie/privacy-and-legal/privacy-statement.
                </a>
              </p>
              <div>
                <Checkbox
                  checked={!!consentChecked}
                  color="primary"
                  required={true}
                  onChange={(e) =>
                    handleInputChange(
                      "confirmInfo",
                      "consentChecked",
                      e.target.checked ? "yes" : ""
                    )
                  }
                />
                I Agree
              </div>
              <div className="my-3">
                {isSigPad ? null : (
                  <>
                    <DCSignature />
                    {errors.signUrl ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          color: "red",
                        }}
                      >
                        {errors.signUrl}
                      </div>
                    ) : null}
                  </>
                )}
              </div>

              {isHospitalName === "mymedical" && (
                <div className="my-3">
                  <p>
                    Medosync Ltd. is a data processor of MyMedical and
                    submitting the claim on behalf of MyMedical.
                  </p>
                  <p>
                    MedoSync Ltd.{" "}
                    <a href="https://www.medosync.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </a>
                  </p>
                  <p>
                    MyMedial Ltd. Privacy Policy can be found on display in the
                    Clinic.
                  </p>
                </div>
              )}
              {isHospitalName === "affidea" && (
                <div className="my-3">
                  <p>
                    Medosync Ltd. is a data processor of Affidea and submitting
                    the claim on behalf of Affidea.
                  </p>
                  <p>
                    MedoSync Ltd.{" "}
                    <a href="https://www.medosync.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </a>
                  </p>
                  <p>
                    Affidea Ltd.{" "}
                    <a href="https://www.affidea.ie/privacy-policy/" target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </a>
                  </p>
                </div>
              )}  

              <div className="my-3">
                <span style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div style={{ textAlignLast: "end" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<Icon>send</Icon>}
                      className="my-3"
                      // type="button"
                      type={"submit"}
                      // onClick={() => props.history.push(`/layaconfirm/${pid}/${token}`)}
                    >
                      Next
                    </Button>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state.confirmInfo,
});

export default connect(mapStateToProps, { handleInputChange, setKeyAction })(
  SignatureRecaptureIL
);
