// TODO: Change emergency contact nunber to Number for keypad to display on phone

import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { handleInputChange, setKeyAction } from "../redux/actions";
import AccidentSectionIL from "../components/PatientRegistration/AccidentSectionIL";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import { useParams, useLocation } from "react-router";
import PolicyDetail from "../components/PatientRegistration/PolicyDetailsEdin";
// import Covid19Details from "../components/PatientRegistration/Covid19";
import DCSignature from "../components/PatientRegistration/consent_signature";
import { makeStyles } from "@material-ui/core/styles";
import RisksAndFalls from "../components/PatientRegistration/RisksAndFallsSection";
import BarriersToLearning from "../components/PatientRegistration/BarriersToLearning";
import { useSmsByTokenQuery } from "../generated/graphql";


import Checkbox from "@material-ui/core/Checkbox";
import { __prod__ /*, __patient_frontend__ */ } from "../constants";

const useStyles = makeStyles((theme) => ({
  sectionHead: {
    backgroundColor: "#82BAA8",
    color: "white",
  },
  fieldContainer: {
    marginTop: "-8px",
    backgroundColor: "white",
    color: "black",
    padding: "10px 5px",
    borderColor: "#B7E0D7",
    borderStyle: "solid",
  },
}));

function Patientregistration({
  history,
  accidentResult,
  handleInputChange,
  setKeyAction,
  signUrl,
  consentChecked,
  statementChecked,
  medosyncChecked,
}) {
  const { token } = useParams();

  const { data } = useSmsByTokenQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "no-cache",
    variables: { token: token },
    context: { passAuthHeader: true },
  });

  //check if the smsToken exists, if not, display a message to the patient
  const smsToken = data?.SMSByToken.length;
  // const smsToken  = window.location;
  // console.log("smsToken", smsToken);
  // console.log("smsToken$$$$", data?.SMSByToken?.token);
  // console.log("location", window.location);
  // console.log("token", token);


  const location = useLocation();
  const isHospitalName = process.env.REACT_APP_USE_HOSPITAL_NAME.toLowerCase();
  const isSigPad = location.pathname.startsWith("/sig");

  const isQRSigIL =
    location.pathname.startsWith("/sig/QR/il") ||
    location.pathname.startsWith("/sig/QR/2");

  const isIL =
    location.pathname.startsWith("/il") || location.pathname.startsWith("/2");

  const isQRIL =
    location.pathname.startsWith("/QR/il") ||
    location.pathname.startsWith("/QR/2");

  // const { pid, appid, hospitalId, token } = useParams();
  const classes = useStyles();
  const [errors, setErrors] = useState({});

  //lifting state up
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [tel, setMobilePhone] = useState("");
  // date picker
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const [title, setTitle] = useState("");
  const [forenames, setFornames] = useState("");
  const [surname, setSurname] = useState("");
  const [tempGender, setGender] = useState("");

  const [pvtPatient, setPvtPatient] = useState("");
  const [GPConsentYesNo, setGPConsentYesNo] = useState("");

  const [tempAddress, setAddress] = useState("");
  const [tempAddress2, setAddress2] = useState("");
  const [tempCity, setCity] = useState("");

  const [emg_firstname, setEmg_firstname] = useState("");
  const [emg_lastname, setEmg_lastname] = useState("");
  const [emg_mobilephone, setEmg_mobilephone] = useState("");

  const [tempZipcode, setZipcode] = useState("");
  const [tempCountryId, setCountryId] = useState(99);
  const [tempStateId, setStateId] = useState(1484);

  const [GPContactDetails, setGPContactDetails] = useState("yes");

  const [GP_FirstName, setGP_FirstName] = useState("");
  const [GP_LastName, setGP_LastName] = useState("");
  const [GP_PracticeName, setGP_PracticeName] = useState("");
  const [GP_Email, setGP_Email] = useState("");
  const [GP_Address, setGP_Address] = useState("");
  const [GP_Town, setGP_Town] = useState("");
  const [GP_PhoneNumber, setGP_PhoneNumber] = useState("");

  const [MM_Patient_Number, setMM_Patient_Number] = useState(null);

  const handleChange = (event) => {
    handleInputChange("injuryDetails", "accidentResult", event.target.value);
    // setSelectedValue(event.target.value);
    if (event.target.value === "no") {
    }
  };

  return (
    <div className="container mt-5">
       {!smsToken ? <div style={{ color: "#007bff" }}>{smsToken === 0 ? "Ask the clinic admin for a new sms registration link as this link was not used within 2 hours and expired" : "Loading..." }</div> :(
      <form
        onSubmit={(e) => {
          dispatch(
            setKeyAction("policyDetails", {
              memberNo: "",
              mrn: "",
              title: title,
              surname: surname,
              forenames: forenames,
              dob: year + "-" + month + "-" + day,
              address: tempAddress,
              gender: tempGender,
              addressline2: tempAddress2,
              city: tempCity,
              zipcode: tempZipcode,
              countryid: tempCountryId,
              stateid: tempStateId,
              tel: tel,
              pvtPatient: pvtPatient,
              GPConsentYesNo: GPConsentYesNo,
              email: email,
              emg_firstname: emg_firstname,
              emg_lastname: emg_lastname,
              emg_mobilephone: emg_mobilephone,
              GPContactDetails: GPContactDetails,
              GP_FirstName: GP_FirstName,
              GP_LastName: GP_LastName,
              GP_PracticeName: GP_PracticeName,
              GP_Email: GP_Email,
              GP_Address: GP_Address,
              GP_Town: GP_Town,
              GP_PhoneNumber: GP_PhoneNumber,
              MM_Patient_Number: MM_Patient_Number,
            })
          );

          e.preventDefault();
          if (!signUrl && !isSigPad) {
            setErrors({ signUrl: "required" });
            return;
          }
          if (isIL || isQRIL) {
            history.push(`/ilconfirm/${token}`);
          } else if (isQRSigIL) {
            history.push(`/sig/QR/2/ts/${token}`);
          }
        }}
      >
        <div
          style={{
            marginTop: "-8px",
            backgroundColor: "white",
            color: "black",
            padding: "5px 5px",
            borderColor: "#42d7f5",
            borderStyle: "solid",
          }}
        >
          {isHospitalName === 'affidea' && (
          <div className="my-3">
            <RisksAndFalls
              onHeaderClick={
                __prod__
                  ? undefined
                  : () => {
                      setKeyAction("covid19", {
                        feverOrChills: "no",
                        cough: "no",
                        shortnessOfBreath: "no",
                        fluLikeSymptoms: "no",
                        smell: "no",
                      });
                      setKeyAction("risksAndFalls", {
                        falls12mt: "no",
                        unsteady: "no",
                        visualImparment: "no",
                        mobilityAid: "no",
                        details: "need zimmer frame",
                      });
                      setKeyAction("barrier2Learning", {
                        Language_Literacy: "yes",
                        Visual_Impairment: "no",
                        Physical: "no",
                        Hearing_Impairment: "yes",
                        Other: "yes",
                      });
                      setKeyAction("policyDetails", {
                        memberNo: "",
                        mrn: "",
                        title: "Mr.",
                        surname: "MedoSync",
                        forenames: "Laya",
                        dob: "1950-12-12",
                        address: "test",
                        addressline2: "some street",
                        city: "test",
                        zipcode: "12345",
                        countryid: 99,
                        stateid: 1484,
                        tel: "0857582281",
                        pvtPatient: "yes",
                        insuranceCompanyId: "",
                        GPConsentYesNo: "yes",
                        ts_Appointment_Time: "",
                        email: "test@test.com",
                        GP_PracticeName: "",
                        EMG_FirstName: "Jackie",
                        EMG_LastName: "Nulan",
                        NextOfKinPhoneNumber: "0881234567",
                        GPContactDetails: "yes",
                      });
                      setKeyAction("illnessDetails", {
                        symptoms: "2022-01-01",
                        consultDate: "2022-01-01",
                        conditions: "no",
                        conditionsDate: "2022-01-01",
                      });
                      setKeyAction("refDetails", {
                        name: "Dr. Tom",
                        date: "2022-01-01",
                        address: "Some Dr Address",
                      });
                      setKeyAction("injuryDetails", {
                        accidentResult: "no",
                        date: "2022-01-01",
                        place: "outside",
                        cause: "fall",
                        otherParty: "yes",
                        nameAndAddress: "other party address",
                        companyName: "not my company",
                        solicitor: "yes",
                        assessmentBoard: "yes",
                        solicitorInformation: "some solicitor address",
                        PIAB_Contact_Name: "",
                      });
                      setKeyAction("confirmInfo", {
                        signUrl: "",
                        statementChecked: "yes",
                        consentChecked: "yes",
                        medosyncChecked: "yes",
                      });
                    }
              }
            />
          </div>)}
          {/* {isHospitalName === "affidea" && (
            <div className="my-3">
              <RisksAndFalls />
            </div>
          )} */}
          {isHospitalName === "affidea" && (
            <div className="my-3">
              <BarriersToLearning />
            </div>
          )}
          <div className="my-3">
            <PolicyDetail
              isLaya={false}
              email={email}
              tel={tel}
              day={day}
              month={month}
              year={year}
              title={title}
              forenames={forenames}
              surname={surname}
              tempGender={tempGender}
              pvtPatient={pvtPatient}
              GPConsentYesNo={GPConsentYesNo}
              tempAddress={tempAddress}
              tempAddress2={tempAddress2}
              tempCity={tempCity}
              emg_firstname={emg_firstname}
              emg_lastname={emg_lastname}
              emg_mobilephone={emg_mobilephone}
              tempZipcode={tempZipcode}
              tempCountryId={tempCountryId}
              tempStateId={tempStateId}
              setEmail={setEmail}
              setMobilePhone={setMobilePhone}
              setDay={setDay}
              setMonth={setMonth}
              setYear={setYear}
              setTitle={setTitle}
              setFornames={setFornames}
              setSurname={setSurname}
              setGender={setGender}
              setPvtPatient={setPvtPatient}
              setGPConsentYesNo={setGPConsentYesNo}
              setAddress={setAddress}
              setAddress2={setAddress2}
              setCity={setCity}
              setEmg_firstname={setEmg_firstname}
              setEmg_lastname={setEmg_lastname}
              setEmg_mobilephone={setEmg_mobilephone}
              setZipcode={setZipcode}
              setCountryId={setCountryId}
              setStateId={setStateId}
              isSelfPay={false}
              setGPContactDetails={setGPContactDetails}
              GPContactDetails={GPContactDetails}
              GP_FirstName={GP_FirstName}
              setGP_FirstName={setGP_FirstName}
              GP_LastName={GP_LastName}
              setGP_LastName={setGP_LastName}
              GP_PracticeName={GP_PracticeName}
              setGP_PracticeName={setGP_PracticeName}
              GP_Email={GP_Email}
              setGP_Email={setGP_Email}
              GP_Address={GP_Address}
              setGP_Address={setGP_Address}
              GP_Town={GP_Town}
              setGP_Town={setGP_Town}
              GP_PhoneNumber={GP_PhoneNumber}
              setGP_PhoneNumber={setGP_PhoneNumber}
              MM_Patient_Number={MM_Patient_Number}
              setMM_Patient_Number={setMM_Patient_Number}
            />
          </div>

          <div className="my-3">
            <div className={classes.sectionHead}>
              <h6 className="p-2">Personal injury claims</h6>
            </div>
            <div className={classes.fieldContainer}>
              <div className="p-3">
                <span>
                  Yes
                  <Radio
                    color="primary"
                    name="choice-3"
                    value="yes"
                    required={true}
                    onChange={handleChange}
                    checked={accidentResult === "yes"}
                  />
                  No
                  <Radio
                    color="primary"
                    name="choice-3"
                    value="no"
                    required={true}
                    onChange={handleChange}
                    checked={accidentResult === "no"}
                  />
                </span>
              </div>

              {accidentResult === "yes" && (
                <div className="my-3">
                  <AccidentSectionIL />
                </div>
              )}
            </div>
          </div>

          <div style={{ marginBottom: 10, marginTop: 20 }}> </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className={classes.sectionHead}>
              <h6 className="p-2">Consent and Declaration</h6>

              <div className={classes.fieldContainer}>
                <h5>Consent</h5>
                <p>
                  I declare that at the time I underwent medical treatment I was
                  a party to a health insurance contract and was entitled to
                  treatment under my Irish Life Health plan. I declare that to
                  the best of my knowledge, the information provided in Part 1
                  of this form is accurate, true and complete. I authorise the
                  doctors or minor injury clinic to furnish Irish Life Health,
                  or any authorised agent it may appoint to act on its behalf,
                  with any information requested, including access to my medical
                  records, where this is necessary in relation to treatment or
                  services received by me or my named dependants in respect of
                  this claim. I understand that only medical information
                  relating to my claim will be requested by Irish Life Health. I
                  authorise the direct payment by Irish Life Health to the
                  doctors/minor injury clinic for the services set out on this
                  claim form to the extent provided for under my Irish Life
                  Health plan. I verify the details of the accounts submitted on
                  my behalf by the doctor/hospital/consultant as an accurate
                  reflection of the treatment I received. I understand that the
                  details of these amounts will be included in my Irish Life
                  Health statement of payment and I will have the opportunity to
                  contact Irish Life Health directly with any queries. Charges
                  not covered under the Irish Life Health plan to which I
                  subscribe will remain my responsibility or that of the named
                  dependant who received the treatment to settle directly with
                  the minor injury clinic concerned.{" "}
                </p>
                <div>
                  <Checkbox
                    checked={!!statementChecked}
                    color="primary"
                    required={true}
                    onChange={(e) =>
                      handleInputChange(
                        "confirmInfo",
                        "statementChecked",
                        e.target.checked ? "yes" : "no"
                      )
                    }
                  />
                  I Agree
                </div>

                <div className="my-3"></div>
                <h5 htmlFor="consent">Declaration</h5>
                <p>
                  I/we confirm that all the details, answers and information
                  given in this form are true, accurate and complete. I/we
                  confirm that I/ we am/are giving my/our permission to you to
                  use the information I/we have given on this form for the
                  purposes set out in the Irish Life Health Privacy Notice which
                  can be found at
                  <a
                    href={
                      "http://www.irishlifehealth.ie/privacy-and-legal/privacy-statement"
                    }
                  >
                    {" "}
                    http://www.irishlifehealth.ie/privacy-and-legal/privacy-statement.
                  </a>
                </p>
                <div>
                  <Checkbox
                    checked={!!consentChecked}
                    color="primary"
                    required={true}
                    onChange={(e) =>
                      handleInputChange(
                        "confirmInfo",
                        "consentChecked",
                        e.target.checked ? "yes" : ""
                      )
                    }
                  />
                  I Agree
                </div>
                <div className="my-3">
                  {isSigPad ? null : (
                    <>
                      <DCSignature />
                      {errors.signUrl ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            color: "red",
                          }}
                        >
                          {errors.signUrl}
                        </div>
                      ) : null}
                    </>
                  )}
                </div>

                {isHospitalName === "mymedical" && (
                <div className="my-3">
                  <p>
                    Medosync Ltd. is a data processor of MyMedical and
                    submitting the claim on behalf of MyMedical.
                  </p>
                  <p>
                    MedoSync Ltd.{" "}
                    <a href="https://www.medosync.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </a>
                  </p>
                  <p>
                    MyMedial Ltd. Privacy Policy can be found on display in the
                    Clinic.
                  </p>
                </div>
              )}
              {isHospitalName === "affidea" && (
                <div className="my-3">
                  <p>
                    Medosync Ltd. is a data processor of Affidea and submitting
                    the claim on behalf of Affidea.
                  </p>
                  <p>
                    MedoSync Ltd.{" "}
                    <a href="https://www.medosync.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </a>
                  </p>
                  <p>
                    Affidea Ltd.{" "}
                    <a href="https://www.affidea.ie/privacy-policy/" target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </a>
                  </p>
                </div>
              )}

                <div className="my-3">
                  <span style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div style={{ textAlignLast: "end" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        endIcon={<Icon>send</Icon>}
                        className="my-3"
                        // type="button"
                        type={"submit"}
                        // onClick={() => props.history.push(`/layaconfirm/${pid}/${token}`)}
                      >
                        Next
                      </Button>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>)}
    </div>
  );
}
const mapStateToProps = (state) => ({
  accidentResult: state.injuryDetails.accidentResult,
  policyDetails: state.policyDetails,
  refDetails: state.refDetails,
  referralresult: state.illnessDetails.referralresult,
  ...state.confirmInfo,
});

export default connect(mapStateToProps, { handleInputChange, setKeyAction })(
  Patientregistration
);
