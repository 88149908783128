import React from "react";
import { connect } from "react-redux";
import { handleInputChange } from "../../redux/actions";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//import CodeInput from '../../components/UI/CodeInput/CodeInput';
import Input from "../../components/UI/Input/Input";
//import { dateConverter } from '../../lib/dateConverter';

const useStyles = makeStyles((theme) => ({
  sectionHead: {
    backgroundColor: "#42b0f5",
    color: "white",
  },
  fieldContainer: {
    marginTop: "-8px",
    backgroundColor: "white",
    color: "black",
    padding: "10px 5px",
    borderColor: "#42d7f5",
    borderStyle: "solid",
  },
}));

function ReferralDetails({
  name,
  refdate,
  address,
  conditionsDate,
  handleInputChange,
}) {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");

  return (
    <div>
      <div
        className={`${classes.fieldContainer} ${
          matches ? "d-flex flex-column" : "d-flex"
        }`}
      >
        <div className="p-2">
          <label>When did you claim for this illness before?</label>
          <Input
            type={"date"}
            value={conditionsDate}
            className="form-control width80"
            required={true}
            onChange={(e) =>
              handleInputChange(
                "illnessDetails",
                "conditionsDate",
                e.target.value
              )
            }
          />
        </div>
      </div>

      <div className={classes.sectionHead}>
        <h6 className="p-2">3 Referral Details</h6>
      </div>
      <div
        className={`${classes.fieldContainer} ${
          matches ? "d-flex flex-column" : "d-flex"
        }`}
      >
        {/* <div className="d-flex w-50"> */}
        <div className="p-2">
          <label htmlFor="dr-name">Name of doctor first attented: </label>
          <Input
            id="dr-name"
            className="form-control width80"
            required={true}
            value={name}
            onChange={(e) =>
              handleInputChange("refDetails", "name", e.target.value)
            }
          />

          <div style={{ marginBottom: 20, marginTop: 20 }}> </div>
          <label>Date when attended:</label>
          <Input
            type={"date"}
            value={refdate}
            id="notice"
            className="form-control width80"
            required={true}
            onChange={(e) =>
              handleInputChange("refDetails", "date", e.target.value)
            }
          />
</div>

</div>
        <div
        className={`${classes.fieldContainer} ${
          matches ? "d-flex flex-column" : "d-flex flex-column"
        }`}
      >
        <div className="p-2">
          <label htmlFor="DrAddress">
          Doctor's Address:{" "}
          </label>
          <textarea
            id="DrAddress"
            cols="32"
            rows="5"
            value={address}
            required={true}
            onChange={(e) =>
              handleInputChange("refDetails", "address", e.target.value)
            }
            className="form-control width80"
          />
        </div>
        </div>

    </div>
  );
}
const mapStateToProps = (state) => ({
  name: state.refDetails.name,
  refdate: state.refDetails.date,
  address: state.refDetails.address,
  conditionsDate: state.illnessDetails.conditionsDate,
});
export default connect(mapStateToProps, { handleInputChange })(ReferralDetails);
