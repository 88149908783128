import { ApolloClient, InMemoryCache } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { split, HttpLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { __apipath__, __authorization__, __apipathws__ } from "./settings";

const setAuthorizationLink = setContext((request, { headers }) => {
  // console.log({request, previousContext})
  return {
    headers: {
      ...headers,
      authorization: __authorization__,
    },
  };
});

const wsLink = new WebSocketLink({
  uri:
    process.env.NODE_ENV === "production"
      ? __apipathws__ + "/graphql"
      : __apipathws__ + "/graphql",
  options: {
    reconnect: true,
    lazy: true,
  },
});

const httpLink = new HttpLink({
  credentials: "include",
  uri: __apipath__ + "/graphql",
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  setAuthorizationLink.concat(httpLink)
);

export const apolloClient = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          fetchPaymentsByOffset: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: false,
            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing = { patients: [], hasMore: false }, incoming) {
              return {
                patients: [...existing.patients, ...incoming.patients],
                hasMore: incoming.hasMore,
              };
            },
          },
        },
      },
    },
  }),
});
