import React from "react";
import ReactCodeInput from "./ReactCodeInput";

let bColor = "#42d7f5";

function CodeInput({
  label = "",
  value,
  fields,
  onChange,
  focus = false,
  isValid = true,
  required = false,
  disabled = false,
  ...props
}) {
  return (
    <div>
      <label htmlFor={label}>{label}</label>
      &nbsp; &nbsp;
      <ReactCodeInput
        type="text"
        id={label}
        fields={fields}
        inputStyle={{
          width: "32px",
          height: "32px",
          borderColor: bColor,
          borderWidth: "1px",
        }}
        inputStyleInvalid={{
          width: "32px",
          height: "32px",
          borderColor: "red",
        }}
        value={value}
        onChange={onChange}
        autoFocus={focus}
        isValid={isValid}
        required={required}
        disabled={disabled}
        {...props}
      />
    </div>
  );
}

CodeInput.defaultProps = {
  fields: 10,
};

export default CodeInput;
