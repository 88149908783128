import React, { useState } from "react";
// import axios from "../config";
import { Link } from "react-router-dom";
import Input from "../components/UI/Input/Input";
import { setUser } from "../redux/actions";
import { useDispatch } from "react-redux";
import { __ms_auth__, __apipath__ } from "../settings";
import { useLoginMutation } from "../generated/graphql";

console.log(__apipath__);

const Login = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState({
    value: "",
    touch: false,
    error: false,
  });

  const [password, setPassword] = useState({
    value: "",
    touch: false,
    error: false,
  });
  const [login] = useLoginMutation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");

    login({
      variables: {
        password: password.value,
        email: email.value,
      },
    })
      .then((res) => {
        setLoading(false);
        let data = res.data?.login;

        if (data !== null) {
          dispatch(setUser(data));
          if (data?.roles === "Doctor")
            props.history.push("/consultant-signature");
          else if (data?.roles === "Admin")
            props.history.push("/admit-patients");
          else if (data?.roles === "Forms") props.history.push("/qr-list");
        } else if (data === null) {
          console.log(error);
          setError(
            "Unauthorized - Check that you are using the correct username and password."
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.message);
        setError(
          error.message +
            " - Check that you are using the correct username and password."
        );
        setLoading(false);
      });
  };
  return (
    <div className="container pt-5 mt-3">
      <div className="d-flex justify-content-center h-100">
        <div className="card">
          <div className="card-header">
            <h3>Sign In </h3>
          </div>
          <div className="card-body">
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <form onSubmit={onSubmit}>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    style={{ backgroundColor: "#42b0f5" }}
                  >
                    <i className="fas fa-user" />
                  </span>
                </div>

                <Input
                  type="email"
                  name="email"
                  value={email.value}
                  onChange={(event) =>
                    setEmail({
                      value: event.target.value,
                      touch: true,
                      error: email.value ? false : true,
                    })
                  }
                />
              </div>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    style={{ backgroundColor: "#42b0f5" }}
                  >
                    <i className="fas fa-key" />
                  </span>
                </div>
                <Input
                  type="password"
                  value={password.value}
                  // autocomplete="current-password"
                  name="password"
                  onChange={(event) =>
                    setPassword({
                      value: event.target.value,
                      touch: true,
                      error: password.value ? false : true,
                    })
                  }
                />
              </div>
              <div className="row align-items-center remember">
                <input type="checkbox" />
                Remember Me
              </div>
              <div className="form-group">
                <input
                  type="submit"
                  value="Login"
                  className="btn float-right login_btn"
                  disabled={loading}
                />
              </div>
            </form>
          </div>

          <div className="card-footer">
            <div className="d-flex justify-content-center">
              <Link to="/forgot-password" className="text-white">
                Forgot your password?
              </Link>
            </div>
            {/* </div>

          <div className="card-footer"> */}
            <div className="d-flex justify-content-center">
              {__ms_auth__ ? (<a
                  href={__apipath__ + "/ms-login"}
                  // href="http://localhost:4004/ms-login"
                  className="block text-base font-medium text-center text-blue-700"
                >
                  Sign in with your Microsoft account
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
