import React from "react";
import DataTable from "react-data-table-component";
import axios from "../config";
import { convertUnixDate, convertUnixDate2 } from "../lib/dateConverter";
import InfiniteScroll from "react-infinite-scroller";
import Header from "../components/UI/Header/Header";

const ExpandedComponent = ({ data }) => (
  <pre>{JSON.stringify(data, null, 2)}</pre>
);

export default class PatientList extends React.Component {
  conditionalCellStyles = (column) => {
    return [
      {
        when: (row) => typeof row[column] === "boolean" && row[column],
        style: {
          backgroundColor: "rgba(63, 195, 128, 0.9)",
          color: "white",
        },
      },
      {
        when: (row) => typeof row[column] === "boolean" && !row[column],
        style: {
          backgroundColor: "rgba(242, 38, 19, 0.9)",
          color: "white",
        },
      },
    ];
  };

  generateColumns = (fields) => {
    return fields.map((f) => ({
      name: f.name,
      selector: f.field,
      sortable: f.sortable ? true : false,
      conditionalCellStyles: this.conditionalCellStyles(f.field),
      format: (row) => (f.format ? f.format(row, f.field) : row[f.field]),
      width: f.width,
      // cell: (row) => f.cell ? f.cell(row, f.field) : row[f.field]
      // format: (row) => { console.log(row) }
    }));
  };

  //   onClickReviewClaim = (patient) => {
  //     this.props.history.push({
  //       pathname: "/review-claim",
  //       state: { patient },
  //     });
  //   };
  columns = this.generateColumns([
    {
      field: "hospitalId",
      name: "hospitalId",
      sortable: true,
      width: "8rem",
      format: (row, col) => <div className="text-wrap">{row[col]}</div>,
    },
    {
      field: "ts_Admit_Date",
      name: "Admit Date",
      sortable: true,
      width: "8rem",
      format: (row, col) => (
        <div className="text-wrap">{convertUnixDate2(row[col])}</div>
      ),
    },
    {
      field: "ts_Discharge_Date",
      name: "Discharge Date",
      sortable: true,
      width: "8rem",
      format: (row, col) => (
        <div className="text-wrap">{convertUnixDate2(row[col])}</div>
      ),
    },
    {
      field: "Patient_Forenames",
      name: "First Name",
      sortable: true,
      width: "8rem",
      format: (row, col) => <div className="text-wrap">{row[col]}</div>,
    },
    {
      field: "Patient_Surname",
      name: "Surname",
      sortable: true,
      width: "8rem",
      format: (row, col) => <div className="text-wrap">{row[col]}</div>,
    },
    {
      field: "DOB",
      name: "DOB",
      sortable: true,
      width: "8rem",
      format: (row, col) => (
        <div className="text-wrap">{convertUnixDate(row[col])}</div>
      ),
    },
    {
      field: "MRN",
      name: "MRN",
      sortable: true,
      width: "8rem",
      format: (row, col) => <div className="text-wrap">{row[col]}</div>,
    },
    {
      field: "Health_Membership_Number",
      name: "Membership NO.",
      sortable: true,
      width: "10rem",
    },
    {
      field: "treatmentsAndDoctorsForInvoice",
      name: "Doctor Name",
      sortable: true,
      format: (row, col) => {
        return (
          <div className="d-flex flex-column">
            {row[col].map((v, i) => {
              return (
                <div
                  key={i}
                  className="cst-treatment-and-doctor text-wrap"
                >{`${v?.doctor?.User_Doctor_Consultant_Name}`}</div>
              );
            })}
          </div>
        );
      },
      width: "10rem",
    },
    {
      field: "treatmentsAndDoctorsForInvoice",
      name: "Treatment Provided",
      sortable: true,
      format: (row, col) => {
        return (
          <div className="d-flex flex-column">
            {row[col].map((v, i) => {
              return (
                <div
                  key={i}
                  className="w-100 cst-treatment-and-doctor text-wrap"
                >{`${v?.treatment?.Treatment}`}</div>
              );
            })}
          </div>
        );
      },
      width: "10rem",
    },
    {
      field: "treatmentsAndDoctorsForInvoice",
      name: "Claim Status",
      sortable: true,
      format: (row, col) => {
        return (
          <div className="d-flex flex-column">
            {row[col].map((v, i) => {
              return (
                <div
                  key={i}
                  className="w-100 cst-treatment-and-doctor text-wrap"
                >{`${v?.invoice?.Insurance_Payment_Status}`}</div>
              );
            })}
          </div>
        );
      },
      width: "10rem",
    },
    {
      field: "editPatient",
      name: "Edit",
      sortable: false,
      format: (row, col) => {
        return (
          <button
            className="btn btn-primary btn-sm btn-block"
            onClick={() => this.props.history.push(`/edit-patient/${row.id}`)}
          >
            edit
          </button>
        );
      },
      width: "10rem",
    },
    {
      field: "refundPatient",
      name: "Refund",
      sortable: false,
      format: (row, col) => {
        return row.treatmentsAndDoctorsForInvoice?.[0]?.invoice?.stripePaymentIntentId ? (
          <button
            className="btn btn-primary btn-sm btn-block"
            onClick={() =>
              this.props.history.push(
                `/refund/${row.treatmentsAndDoctorsForInvoice[0].invoice.stripePaymentIntentId}`
              )
            }
          >
            refund
          </button>
        ) : null;
      },
      width: "10rem",
    },
    // {
    //   field: "treatmentsAndDoctorsForInvoice",
    //   format: (row, col) => {
    //     return (
    //       <div className="w-100 cst-treatment-and-doctor text-wrap">
    //         <button
    //           style={{ width: "9rem", margin: 10, position: "relative" }}
    //           className="btn btn-primary btn-sm btn-block"
    //           onClick={() => this.onClickReviewClaim(row)}
    //         >
    //           {"Review Claim"}
    //         </button>
    //       </div>
    //     );
    //   },
    //   width: "14rem",
    // },
  ]);

  state = {
    loading: true,
    patients: [],
    offset: 0,
    hasMore: false,
    perPage: 10,
    isAdmited: true,
  };
  fetchPatients = () => {
    const graphqlQuery = {
      query: `query {
                patientsbyoffset(offset:${this.state.offset}, perPage:${this.state.perPage}, isAdmited:${this.state.isAdmited} ) {
                patients {
                  name
                  id
                  uuid
                  Patient_Title
                  Patient_Forenames
                  Patient_Surname
                  fever_or_Chills_YesNo
                  cough_YesNo
                  shortness_of_breath_YesNo
                  flu_like_symptoms_YesNo
                  exposed_to_confirmed_Covid19_case_YesNo
                  Travel_abroad_last_two_weeks_YesNo
                  Worked_In_Covid19_Healthcare_facility_abroad_YesNo
                  Health_Insurance_Company
                  Health_Membership_Number
                  MRN
                  DOB
                  Address
                  Phone_Number
                  Did_Patient_Elect_To_Be_Private_YesNo
                  ts_Symptoms_First_Noticed
                  ts_Doctor_First_Consulted_MedHistory
                  ts_Date_When_Claimed_For_This_Illness_Before
                  Name_Of_Doctor_Fisrt_Attended_Referral
                  ts_Date_Of_Doctor_First_Attended_Referral
                  Address_Of_Doctor_First_Attended_Referral
                  Admission_IsResult_Of_Accident_YesNo
                  ts_Date_of_Accident
                  Was_Accident_Injury_Fault_of_Another_Party_YesNo
                  Contact_Information_of_Responsible_Party
                  Responsible_Party_Insurance_Company_Information
                  Are_You_Claiming_Expenses_Via_PIAB_YesNo
                  Are_You_Claiming_Expenses_Via_Solicitor_YesNo
                  Name_Address_of_Solicitor_If_Applicable
                  ts_Date_Patient_Signature
                  ts_Admit_Date
                  ts_Discharge_Date
                  #ts_Doctor_Consultant_Signature
                  PatientPaidByCashOrCard
                  hospitalId
                  treatmentsAndDoctorsForInvoice {
                    treatment {
                      id
                      Cost
                      Treatment
                      Hospital_Name
                    }
                    doctor {
                      name
                      id
                      uuid
                      User_Doctor_Consultant_Name
                      Medical_Council_Number
                      Laya_Healthcare_Doctor_Consultant_Code
                      Participating_Benefit_YesNo
                      User_Doctor_Consultant_Signature
                    }
                    invoice{
                      stripePaymentIntentId
                        Patient_Invoice_Amount
                        Insurance_Invoice_Amount
                        Maximum_Cover
                        ts_Insurance_Payment_Status_Date
                        Insurance_Payment_Status
                        id
                    }
                  }
                }
              }
            }
              `,
    };
    axios
      .post("", graphqlQuery)
      .then((res) => {
        const data = res.data.data;
        let search = this.props.history.location.search.split("=")[1];
        if (data?.patientsbyoffset.patients) {
          let newPatients = data["patientsbyoffset"].patients;
          let filteredPatients = newPatients.filter((v, i) => {
            if (
              v.treatmentsAndDoctorsForInvoice
                .length /*&& v.ts_Doctor_Consultant_Signature*/ &&
              v.ts_Discharge_Date
            )
              return v;
            return null;
          });
          if (search === "Pay") {
            filteredPatients = filteredPatients.filter((v, i) => {
              if (
                v.treatmentsAndDoctorsForInvoice.length &&
                v.treatmentsAndDoctorsForInvoice[0]?.invoice
                  .Insurance_Payment_Status === "Pay"
              ) {
                return v;
              }
              return null;
            });
          } else if (search === "Reject") {
            filteredPatients = filteredPatients.filter((v, i) => {
              if (
                v.treatmentsAndDoctorsForInvoice.length &&
                v.treatmentsAndDoctorsForInvoice[0]?.invoice
                  .Insurance_Payment_Status === "Reject"
              ) {
                return v;
              }
              return null;
            });
          } else if (search === "RequestForFurtherInfo") {
            filteredPatients = filteredPatients.filter((v, i) => {
              if (
                v.treatmentsAndDoctorsForInvoice.length &&
                v.treatmentsAndDoctorsForInvoice[0]?.invoice
                  .Insurance_Payment_Status ===
                  "Request for Further Information"
              ) {
                return v;
              }
              return null;
            });
          } else if (search === "AwaitingDecision") {
            filteredPatients = filteredPatients.filter((v, i) => {
              if (
                v.treatmentsAndDoctorsForInvoice.length &&
                v.treatmentsAndDoctorsForInvoice[0]?.invoice
                  .Insurance_Payment_Status === "Awaiting Decision"
              ) {
                return v;
              }
              return null;
            });
          } else if (search === "AwaitingDoctorSignature") {
            filteredPatients = filteredPatients.filter((v, i) => {
              if (
                v.treatmentsAndDoctorsForInvoice.length &&
                v.treatmentsAndDoctorsForInvoice[0]?.invoice
                  .Insurance_Payment_Status === "Awaiting Doctor Signature"
              ) {
                return v;
              }
              return null;
            });
          } else if (search === "Submitted") {
            filteredPatients = filteredPatients.filter((v, i) => {
              if (
                v.treatmentsAndDoctorsForInvoice.length &&
                v.treatmentsAndDoctorsForInvoice[0]?.invoice
                  .Insurance_Payment_Status === "Submitted"
              ) {
                return v;
              }
              return null;
            });
          } else if (search === "Paid") {
            filteredPatients = filteredPatients.filter((v, i) => {
              if (
                v.treatmentsAndDoctorsForInvoice.length &&
                v.treatmentsAndDoctorsForInvoice[0]?.invoice
                  .Insurance_Payment_Status === "Paid"
              ) {
                return v;
              }
              return null;
            });
          }
          let concatPatients = [...this.state.patients, ...filteredPatients];
          this.setState({
            patients: concatPatients,
            loading: false,
            offset: this.state.offset + this.state.perPage,
            hasMore: newPatients.length === this.state.perPage ? true : false,
          });

          // this.setState({ patients: patients, loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        //console.log(error)
      });
  };
  viewClaimsSelect = (selected) => {
    // console.log(this.viewClaimsSelect.selected);
    if (selected === "Pay") {
      this.props.history.push("/patient-list?claims=Pay");
      this.setState(
        {
          loading: true,
          patients: [],
          offset: 0,
          hasMore: false,
          perPage: 10,
          isAdmited: true,
        },
        () => {
          this.fetchPatients();
        }
      );
    } else if (selected === "Reject") {
      this.props.history.push("/patient-list?claims=Reject");
      this.setState(
        {
          loading: true,
          patients: [],
          offset: 0,
          hasMore: false,
          perPage: 10,
          isAdmited: true,
        },
        () => {
          this.fetchPatients();
        }
      );
    } else if (selected === "RequestForFurtherInfo") {
      this.props.history.push("/patient-list?claims=RequestForFurtherInfo");
      this.setState(
        {
          loading: true,
          patients: [],
          offset: 0,
          hasMore: false,
          perPage: 10,
          isAdmited: true,
        },
        () => {
          this.fetchPatients();
        }
      );
    } else if (selected === "AwaitingDecision") {
      this.props.history.push("/patient-list?claims=AwaitingDecision");
      this.setState(
        {
          loading: true,
          patients: [],
          offset: 0,
          hasMore: false,
          perPage: 10,
          isAdmited: true,
        },
        () => {
          this.fetchPatients();
        }
      );
    } else if (selected === "AwaitingDoctorSignature") {
      this.props.history.push("/patient-list?claims=AwaitingDoctorSignature");
      this.setState(
        {
          loading: true,
          patients: [],
          offset: 0,
          hasMore: false,
          perPage: 10,
          isAdmited: true,
        },
        () => {
          this.fetchPatients();
        }
      );
    } else if (selected === "Submitted") {
      this.props.history.push("/patient-list?claims=Submitted");
      this.setState(
        {
          loading: true,
          patients: [],
          offset: 0,
          hasMore: false,
          perPage: 10,
          isAdmited: true,
        },
        () => {
          this.fetchPatients();
        }
      );
    } else if (selected === "Paid") {
      this.props.history.push("/patient-list?claims=Paid");
      this.setState(
        {
          loading: true,
          patients: [],
          offset: 0,
          hasMore: false,
          perPage: 10,
          isAdmited: true,
        },
        () => {
          this.fetchPatients();
        }
      );
    } else if (selected === "All") {
      this.props.history.push("/patient-list?claims=All");
      this.setState(
        {
          loading: true,
          patients: [],
          offset: 0,
          hasMore: false,
          perPage: 10,
          isAdmited: true,
        },
        () => {
          this.fetchPatients();
        }
      );
    }
  };

  componentDidMount() {
    let location = this.props.history.location;

    if (!location.search) {
      this.props.history.replace("/patient-list?claims=AwaitingDecision");
    }

    this.fetchPatients();
  }

  render() {
    let search = this.props.history.location.search.split("=")[1];
    return (
      <div className="container-fluid">
        <Header>
          <>
            <div className="d-flex">
              <button
                className="btn btn-danger btn-sm btn-block mr-2"
                onClick={() => {
                  this.viewClaimsSelect("AwaitingDoctorSignature");
                }}
              >
                Awaiting Doctor Signature
              </button>
            </div>
            <div className="d-flex">
              <button
                className="btn btn-warning btn-sm btn-block mr-2"
                onClick={() => {
                  this.viewClaimsSelect("AwaitingDecision");
                }}
              >
                Doctor Has Signed
              </button>
            </div>

            <div className="dropdown mr-2">
              <button
                className="btn btn-secondary dropdown-toggle btn-sm mr-5"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                All Reports
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <span
                  className="dropdown-item cursor-pointer "
                  onClick={() => this.viewClaimsSelect("All")}
                >
                  All
                </span>
                <span
                  className="dropdown-item cursor-pointer "
                  onClick={() => this.viewClaimsSelect("Pay")}
                >
                  Pay
                </span>
                <span
                  className="dropdown-item cursor-pointer "
                  onClick={() => this.viewClaimsSelect("Reject")}
                >
                  Rejected
                </span>
                <span
                  className="dropdown-item cursor-pointer "
                  onClick={() => this.viewClaimsSelect("RequestForFurtherInfo")}
                >
                  Requiring further information{" "}
                </span>
                <span
                  className="dropdown-item cursor-pointer "
                  onClick={() =>
                    this.viewClaimsSelect("AwaitingDoctorSignature")
                  }
                >
                  Awaiting Doctor Signature{" "}
                </span>
                <span
                  className="dropdown-item cursor-pointer "
                  onClick={() => this.viewClaimsSelect("AwaitingDecision")}
                >
                  Awaiting Decision{" "}
                </span>
                <span
                  className="dropdown-item cursor-pointer "
                  onClick={() => this.viewClaimsSelect("Submitted")}
                >
                  Submitted to Insurer{" "}
                </span>
                <span
                  className="dropdown-item cursor-pointer "
                  onClick={() => this.viewClaimsSelect("Paid")}
                >
                  Paid
                </span>
              </div>
            </div>
            <button
              className="btn btn-primary btn-sm px-4"
              onClick={() => {
                this.props.history.replace({
                  pathname: "/admit-patients",
                });
              }}
            >
              Admit Patients
            </button>
          </>
        </Header>
        <InfiniteScroll
          pageStart={0}
          loadMore={this.fetchPatients}
          hasMore={this.state.hasMore}
          loader={
            <div key={0} className="text-center">
              {" "}
              <font color="blue">Loading ...</font>
            </div>
          }
        >
          <DataTable
            expandableRows
            expandableRowsComponent={<ExpandedComponent />}
            title={`Insurance Claims - ${search} `}
            columns={this.columns}
            className="table table-responsive"
            overflowY={true}
            striped={true}
            noDataComponent={<div>Nothing to Display!</div>}
            progressPending={this.state.loading}
            progressComponent={
              <div key={0} className="text-center">
                {" "}
                <font color="blue">Loading ...</font>
              </div>
            }
            data={this.state.patients}
            customStyles={{
              headCells: {
                style: {
                  fontWeight: 600,
                },
              },
            }}
          />
        </InfiniteScroll>
      </div>
    );
  }
}
