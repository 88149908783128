import React from "react";
// import { Link } from "react-router-dom";
import { __clinicName__ } from "../../../settings";
import Img1 from "../../../assets/images/LogoMedoSync.png";
import Img2 from "../../../assets/images/LogoLaya.png";
import Img3 from "../../../assets/images/SignatureMissing.png";
import Img4 from "../../../assets/images/Affidea.jpeg";

export function LogoMedoSync() {
  return (
      <img src={Img1} alt="" />
  );
}

export function LogoClinic() {
  return __clinicName__ === "mymedical" ? (
    <img src={Img2} alt="" />
  ) : (
    <img src={Img4} alt="" />
  );
}

export function SignatureMissing() {
  return <img src={Img3} alt="" />;
}
