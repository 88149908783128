import React from "react";
import DataTable from "react-data-table-component";
import axios from "../config";
import { convertUnixDate, convertUnixDate2 } from "../lib/dateConverter";
import InfiniteScroll from "react-infinite-scroller";
import Header from "../components/UI/Header/Header";
// import { useHistory } from "react-router";

const ExpandedComponent = ({ data }) => (
  <pre>{JSON.stringify(data, null, 2)}</pre>
);

export default class FetchRejectedTreatments extends React.Component {
  conditionalCellStyles = (column) => {
    return [
      {
        when: (row) => typeof row[column] === "boolean" && row[column],
        style: {
          backgroundColor: "rgba(63, 195, 128, 0.9)",
          color: "white",
        },
      },
      {
        when: (row) => typeof row[column] === "boolean" && !row[column],
        style: {
          backgroundColor: "rgba(242, 38, 19, 0.9)",
          color: "white",
        },
      },
    ];
  };

  generateColumns = (fields) => {
    return fields.map((f) => ({
      name: f.name,
      selector: f.field,
      sortable: f.sortable ? true : false,
      conditionalCellStyles: this.conditionalCellStyles(f.field),
      format: (row) => (f.format ? f.format(row, f.field) : row[f.field]),
      width: f.width,
      // cell: (row) => f.cell ? f.cell(row, f.field) : row[f.field]
      // format: (row) => { console.log(row) }
    }));
  };
  // console.log( <time datetime={new Date(810514800000).toISOString()}>{timeago(810514800000)}</time>);

  columns = this.generateColumns([
    {
      field: "ts_Admit_Date",
      name: "Admit Date",
      sortable: true,
      width: "12rem",
      format: (row, col) => (
        <div className="text-wrap">{convertUnixDate2(row[col])}</div>
      ),
    },
    {
      field: "Patient_Forenames",
      name: "First Name",
      sortable: true,
      width: "8rem",
      format: (row, col) => <div className="text-wrap">{row[col]}</div>,
    },
    {
      field: "Patient_Surname",
      name: "Last Name",
      sortable: true,
      width: "8rem",
      format: (row, col) => <div className="text-wrap">{row[col]}</div>,
    },
    {
      field: "DOB",
      name: "DOB",
      sortable: true,
      width: "8rem",
      format: (row, col) => (
        <div className="text-wrap">{convertUnixDate(row[col])}</div>
      ),
    },
    {
      field: "MRN",
      name: "MRN",
      sortable: true,
      width: "8rem",
      format: (row, col) => <div className="text-wrap">{row[col]}</div>,
    },
    {
      field: "Health_Membership_Number",
      name: "Membership NO.",
      sortable: true,
      width: "10rem",
    },
    {
      field: "id",
      name: "Add Treatment",
      format: (row) => {
        return (
          <div className="cst-treatment-and-doctor text-wrap">
            {row.rejectedByConsultant_True_False === "true" ? (
              <button
                disabled={row.rejectedByConsultant_True_False === "false"}
                className="btn btn-danger btn-sm"
                onClick={() =>
                  this.props.history.push({
                    pathname: "/add-doctor-treatment",
                    state: {
                      patient: row,
                    },
                  })
                }
              >
                Edit Treatment
              </button>
            ) : (
              <button
                disabled={row.rejectedByConsultant_True_False === "true"}
                className="btn btn-primary btn-sm"
                onClick={() =>
                  this.props.history.push({
                    pathname: "/add-doctor-treatment",
                    state: {
                      patient: row,
                    },
                  })
                }
              >
                Add Treatment
              </button>
            )}
          </div>
        );
      },
    },
    {
      field: "Edit Patient Details",
      name: "Edit Patient Details",
      format: (row) => {
        return (
          <div className="w-100 cst-treatment-and-doctor text-wrap">
            <button
              // disabled={row.rejectedByConsultant_True_False === "true"}
              className="btn btn-secondary btn-sm"
              onClick={() =>
                this.props.history.push({
                  pathname: `/edit-patient/${row.id}`,
                  state: {
                    patient: row,
                  },
                })
              }
            >
              Edit Patient Details
            </button>
          </div>
        );
      },
      //  width: "12rem",
    },
    {
      field: "id",
      name: "Notes",
      format: (row) => {
        return (
          <div className="cst-treatment-and-doctor text-wrap">
            {row.rejectedByConsultant_True_False === "true" ? (
              <span className="text-danger">
                {row?.rejectedByConsultant_Reason}
              </span>
            ) : (
              <span className="text-danger"></span>
            )}
          </div>
        );
      },
    },
  ]);

  state = {
    loading: true,
    patients: [],
    offset: 0,
    hasMore: false,
    perPage: 10,
    isAdmited: true,
  };
  //patientsbyoffset(offset:${this.state.offset}) {
  //

  fetchPatients = () => {
    //console.log("$$$$$$$");
    //console.log(this.state.offset);
    // const token = localStorage.getItem('md_token');
    const graphqlQuery = {
      query: `query {
                fetchPTRDrOffset(offset:${this.state.offset}, perPage:${this.state.perPage}, isAdmited:${this.state.isAdmited} ) {
                  patients {
                  name
                  id
                  uuid
                  Patient_Title
                  Patient_Forenames
                  Patient_Surname
                  fever_or_Chills_YesNo
                  cough_YesNo
                  shortness_of_breath_YesNo
                  flu_like_symptoms_YesNo
                  exposed_to_confirmed_Covid19_case_YesNo
                  Travel_abroad_last_two_weeks_YesNo
                  Worked_In_Covid19_Healthcare_facility_abroad_YesNo
                  Health_Insurance_Company
                  Health_Membership_Number
                  MRN
                  DOB
                  Address
                  Phone_Number
                  Did_Patient_Elect_To_Be_Private_YesNo
                  ts_Symptoms_First_Noticed
                  ts_Doctor_First_Consulted_MedHistory
                  ts_Date_When_Claimed_For_This_Illness_Before
                  Name_Of_Doctor_Fisrt_Attended_Referral
                  ts_Date_Of_Doctor_First_Attended_Referral
                  Address_Of_Doctor_First_Attended_Referral
                  Admission_IsResult_Of_Accident_YesNo
                  ts_Date_of_Accident
                  Was_Accident_Injury_Fault_of_Another_Party_YesNo
                  Contact_Information_of_Responsible_Party
                  Responsible_Party_Insurance_Company_Information
                  Are_You_Claiming_Expenses_Via_PIAB_YesNo
                  Are_You_Claiming_Expenses_Via_Solicitor_YesNo
                  Name_Address_of_Solicitor_If_Applicable
                  ts_Date_Patient_Signature
                  ts_Admit_Date
                  ts_Discharge_Date
                  rejectedByConsultant_True_False
                  PatientPaidByCashOrCard
                  hospitalId
                  rejectedByConsultant_Reason
                  treatmentsAndDoctorsForInvoice {
                    treatment {
                      id
                      Cost
                      Treatment
                      Hospital_Name
                    }
                    doctor {
                      name
                      id
                      uuid
                      User_Doctor_Consultant_Name
                      Medical_Council_Number
                      Laya_Healthcare_Doctor_Consultant_Code
                      Participating_Benefit_YesNo
                      User_Doctor_Consultant_Signature
                    }
                    invoice{
                        Patient_Invoice_Amount
                        Insurance_Invoice_Amount
                        Maximum_Cover
                        ts_Insurance_Payment_Status_Date
                        Insurance_Payment_Status
                        id
                    }
                  }
                  }
                }
              }

              `,
    };
    axios
      .post("", graphqlQuery, {
        // headers: {
        //     authorization: 'bearer ' + token
        // }
      })
      .then((res) => {
        const data = res.data.data;
        console.log(data);
        if (data?.fetchPTRDrOffset.patients) {
          let newPatients = data["fetchPTRDrOffset"].patients;
          let filteredPatients = newPatients.filter((v) => {
            return v.rejectedByConsultant_True_False === "true";
          });
          let concatPatients = [...this.state.patients, ...filteredPatients];

          this.setState({
            patients: concatPatients,
            loading: false,
            offset: this.state.offset + this.state.perPage,
            hasMore: newPatients.length === this.state.perPage ? true : false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  };
  componentDidMount() {
    this.fetchPatients();
    console.log(this.fetchPatients);
    console.log(this.state.patients);
  }

  render() {
    return (
      <div className="container-fluid">
        <Header>
          <>
            <div className="p-1"> </div>
            <button
              className="btn btn-outline-primary btn-sm px-4"
              onClick={() => {
                this.props.history.replace({
                  pathname: "/admit-patients",
                });
              }}
            >
              Admit Patients
            </button>
          </>
        </Header>

        <InfiniteScroll
          pageStart={0}
          loadMore={this.fetchPatients}
          hasMore={this.state.hasMore}
          loader={
            <div key={0} className="text-center">
              {" "}
              <font color="blue">Loading ...</font>
            </div>
          }
        >
          <DataTable
            expandableRows
            expandableRowsComponent={<ExpandedComponent />}
            title="Patient Checkout"
            columns={this.columns}
            className="table table-responsive table-striped"
            overflowY={true}
            striped={true}
            noDataComponent={<div>Loading or Nothing to Display!</div>}
            progressPending={this.state.loading}
            progressComponent={
              <div key={0} className="text-center">
                {" "}
                <font color="blue">Loading ...</font>
              </div>
            }
            data={this.state.patients}
            customStyles={{
              headCells: {
                style: {
                  fontWeight: 600,
                },
              },
            }}
          />
        </InfiniteScroll>
      </div>
    );
  }
}
