import React from "react";
import { withStyles } from "@material-ui/core/styles";
// import Button from '@material-ui/core/Button';
import SignatureCanvas from "react-signature-canvas";
// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Icon from '@material-ui/core/Icon';
import { withRouter } from "react-router-dom";
import { handleInputChange } from "../../redux/actions";
import { connect } from "react-redux";
// import queryString from 'query-string';
// import { useParams } from 'react-router';
const screenWidth = window.screen.width;

const styles = (theme) => ({
  sectionHead: {
    backgroundColor: "#42b0f5",
    color: "white",
  },
  fieldContainer: {
    marginTop: "-8px",
    backgroundColor: "white",
    color: "black",
    padding: "5px 5px",
    // borderColor: "#42d7f5",
    // borderStyle: "solid",
  },
});

class DCSignature extends React.Component {
  constructor(props) {
    super(props);
    this.sigCanvas = React.createRef();
    this._setTimeout = null;
    this.state = {
      statementChecked: false,
      consentChecked: false,
      medoConsentChecked: false,
      disableSubmit: true,
      signCanvasWidth: 0,
      error: false,
      errorMessage: "",
    };
    this.containerRef = null;
  }

  // loading = React.useState(false);
  componentWillUnmount() {
    if (this._setTimeout) {
      clearTimeout(this._setTimeout);
    }
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  enableDisableSubmitBtn = () => {
    if (
      this.state.statementChecked &&
      this.state.consentChecked &&
      this.state.medoConsentChecked &&
      !this.sigCanvas.current.isEmpty()
    ) {
      this.setState({ disableSubmit: false });
    } else if (
      !this.state.statementChecked ||
      !this.state.consentChecked ||
      !this.state.medoConsentChecked ||
      this.sigCanvas.current.isEmpty()
    ) {
      this.setState({ disableSubmit: true });
    }
  };

  componentDidMount() {
    this.setState({ signCanvasWidth: this.containerRef.clientWidth - 15 });
  }

  render() {
    // const { classes, history } = this.props;
    const { classes } = this.props;
    return (
      <div
        className={`${classes.fieldContainer} ${
          screenWidth < 600 ? "d-flex flex-column" : "d-flex flex-column"
        } p-2`}
      >
        <div className="my-2"></div>

        <div ref={(ref) => (this.containerRef = ref)}>
          <div className="p-2">
          <span> Please sign your full name in CAPITAL LETTERS </span>
          <br></br>
          <i><span style={{ color: "red" }}> * Minor signatures are not accepted for insurance purposes </span></i>
          </div>
          <div className="d-flex">
            <SignatureCanvas
              ref={this.sigCanvas}
              penColor="black"
              minDistance={2}
              onEnd={() =>
                this.props.handleInputChange(
                  "confirmInfo",
                  "signUrl",
                  this.sigCanvas.current.toDataURL()
                )
              }
              canvasProps={{
                width: this.state.signCanvasWidth,
                height: 200,
                className: "sigCanvas border ",
              }}
            />
          </div>
          {/* style={{ cursor: "pointer", color: "red" }} */}
          <div className="p-1"></div>
          <button
          variant="contained"
          className="btn btn-secondary btn-sm px-4"
            onClick={() => {
              this.sigCanvas.current.clear();
                this.props.handleInputChange(
                  "confirmInfo",
                  "signUrl",
                  ''
                )
            }}
          >
            Clear Signature
            </button>
        </div>
      </div>
    );
  }
}

export default connect(null, { handleInputChange })(
  withRouter(withStyles(styles)(DCSignature))
);
