import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { StorageService } from '../../redux/StorageService';
const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => (
            (StorageService.getUser())
                ? <Component {...props} />
                : <Redirect to={"/"} />
        )} />
    )
}
export default PrivateRoute;
