export class StorageService {
  static setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }
  static getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
  static setTerminal(terminal) {
    localStorage.setItem("terminal", JSON.stringify(terminal));
  }
  static clearStorage() {
    localStorage.clear();
  }
  static getTerminal() {
    return JSON.parse(localStorage.getItem("terminal"));
  }
}
