import moment from "moment";

export const dateConverter = (e) => {
  let year = e.substring(4, 8);
  let month = e.substring(2, 4);
  let day = e.substring(0, 2);
  year += "-" + year.substring(4);
  month += "-" + month.substring(6);
  day += day.substring(8);
  const date = year + month + day;
  return formatDate(date);
};

export const dateAptConverter = (e) => {
  let min = e.substring(10, 12);
  let hour = e.substring(8, 10);
  let year = e.substring(4, 8);
  let month = e.substring(2, 4);
  let day = e.substring(0, 2);

  year += "-" + year.substring(4);
  month += "-" + month.substring(6);
  day += " " + day.substring(8);
  hour += ":" + hour.substring(10);
  min += min.substring(12);
  const date = year + month + day + hour + min;
  return formatDate(date);
};

export function formatDate(date) {
  return moment(date).format("YYYY-MM-DD HH:mm");
}

export function formatDOB(date) {
  return moment(date).format("YYYY-MM-DD");
}

export const formatDOBB = (date) => {
  let dateToNumber = Number(date);
  return moment(isNaN(dateToNumber) ? date : dateToNumber).format("DD-MM-YYYY");
};

export function formatDate2(date) {
  return moment(date).format("YYYY-MM-DD");
}

export const convertUnixDate = (date) => {
  let dateToNumber = Number(date);
  return moment(isNaN(dateToNumber) ? date : dateToNumber).format("DD-MM-YYYY");
};

export const convertUnixDate2 = (date) => {
  let dateToNumber = Number(date);
  return moment(isNaN(dateToNumber) ? date : dateToNumber).format(
    "DD-MM-YYYY HH:mm"
  );
};
export const convertUnixDate3 = (date) => {
  let dateToNumber = Number(date);
  return moment(isNaN(dateToNumber) ? date : dateToNumber).format(
    "YYYY-MM-DD HH:mm"
  );
};
export const convertUnixDate4 = (date) => {
  let dateToNumber = Number(date);
  return moment(isNaN(dateToNumber) ? date : dateToNumber).format("YYYY-MM-DD");
};
export const toDate = (date) => {
  let dateToNumber = Number(date);
  return new Date(isNaN(dateToNumber) ? date : dateToNumber);
};
