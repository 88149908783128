import React, { useState } from "react";
import { connect } from "react-redux";
import { handleInputChange, setKeyAction } from "../redux/actions";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import { useParams, useLocation } from "react-router";
import DCSignature from "../components/PatientRegistration/consent_signature";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  sectionHead: {
    backgroundColor: "#42b0f5",
    color: "white",
  },
  fieldContainer: {
    marginTop: "-8px",
    backgroundColor: "white",
    color: "black",
    padding: "10px 5px",
    borderColor: "#42d7f5",
    borderStyle: "solid",
  },
}));

function SignatureRecapture({
  history,
  handleInputChange,
  signUrl,
  consentChecked,
  statementChecked,
  medosyncChecked,
}) {
  const location = useLocation();
  const isSigPad = location.pathname.startsWith("/sig/recapture");
  const isLaya = location.pathname.startsWith("/recapture");
  const { pid, token } = useParams();
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const maybePid = pid || ``;
  const maybeToken = token || ``;
  const isHospitalName = process.env.REACT_APP_USE_HOSPITAL_NAME.toLowerCase();

  return (
    <div className="container mt-5">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!signUrl && !isSigPad) {
            setErrors({ signUrl: "required" });
            return;
          }
          if (isSigPad) {
            history.push(`/sig/getSignature/${maybePid}`);
          }

          if (isLaya) {
            history.push(`/recaptureconfirm/${maybePid}/${maybeToken}`);
          }
        }}
      >
        <div style={{ marginBottom: 10, marginTop: 20 }}> </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className={classes.sectionHead}>
            <h6 className="p-4">5 Declaration</h6>

            <div className={classes.fieldContainer}>
              <h5 className="font-weight-bold" style={{ color: "#C12F67" }}>
                Data Protection Statement
              </h5>
              <p>
                “Personal Information” identifies and relates to you or other
                individuals (e.g. your partner or other members of your family).
                If you provide Personal Information about another individual,
                you must (unless we agree otherwise) inform the individual about
                the content of this notice and our Privacy Policy and obtain
                their permission (where possible) to share their Personal
                Information with us. Depending on our relationship with you,
                Personal Information collected may include: contact information,
                financial information and account details, sensitive information
                about health or medical conditions (collected with your consent
                where required by applicable law) or (where we require it and
                are legally permitted to collect it). Personal Information may
                be used for the following purposes:
              </p>
              <ul>
                <li>
                  Insurance administration, e.g. communications, claims
                  processing and payment
                </li>
                <li>
                  Assessments and decisions about the provision and terms of
                  insurance and the settlement of claims including but not
                  limited to: a) analyse,examine or clinically audit the care,
                  claims processes and treatment/ overnight-stay/ convalescence
                  /care pathway options applied/utilised by medical service
                  providers; b) to undertakeinvestigations into, and to
                  adjudicate on, patient’s claim (including investigations into
                  the length of the patient’s hospital stay and the treatment
                  received whilst in hospital)
                </li>
                <li>Assistance and advice on medical and travel matters</li>
                <li>
                  Management of our business operations and IT infrastructure
                </li>
                <li>
                  Prevention, detection and investigation of crime, e.g. fraud
                  and money laundering
                </li>
                <li>Establishment and defence of legal rights</li>
                <li>
                  Legal and regulatory compliance (including compliance with
                  laws and regulations outside your country of residence)
                </li>
                <li>
                  Monitoring and recording of telephone calls for quality,
                  training, and security purposes
                </li>
                <li>
                  Audit of medical service providers and the handling of claims
                  by a medical services provider
                </li>
                <li>Marketing, market research and analysis</li>
              </ul>
              <p>
                For the above purposes, Personal Information may be shared with
                our group companies and third parties (such as insurance
                distribution parties, healthcare professionals and other service
                providers). Personal Information will be shared with other third
                parties (including government authorities) if required by laws
                or regulations. Appropriate technical and physical security
                measures are used to keep your Personal Information safe and
                secure. When we provide Personal Information to a third party
                (including our service providers) or engage a third party to
                collect Personal Information on our behalf, the third party will
                be selected carefully and required to use appropriate security
                measures. You have a number of rights under data protection law
                in connection with our use of your Personal Information. These
                rights may only apply in certain circumstances and are subject
                to certain exemptions. These rights may include a right to
                access Personal Information, a right to request that we correct
                inaccurate data, erase data, or suspend our use of data. These
                rights may also include a right to transfer your data to another
                organisation, a right to object to our use of your Personal
                Information, a right to request that certain automated decisions
                we make have human involvement, a right to withdraw consent and
                a right to complain to the data protection regulator in your
                country. Further information about your rights and how you may
                exercise them is set out in full in our Privacy Policy (see
                below). More details about your rights and how we collect, use
                and disclose your Personal Information can be found in our full
                Privacy Policy which is available at
                https://www.layahealthcare.ie/privacypolicy or upon request by
                writing to Privacy Lead, LayaHealthcare, Eastgate Road, Eastgate
                Business Park, Little Island, Co Cork, T45 E181 or by emailing
                info@ layahealthcare.ie
              </p>

              <div>
                <Checkbox
                  checked={!!statementChecked}
                  color="primary"
                  required={true}
                  onChange={(e) =>
                    handleInputChange(
                      "confirmInfo",
                      "statementChecked",
                      e.target.checked ? "yes" : "no"
                    )
                  }
                />
                I Agree
              </div>

              <h5
                htmlFor="consent"
                className="font-weight-bold"
                style={{ color: "#C12F67" }}
              >
                Declaration
              </h5>
              <p>
                I declare that at the time the expenses were incurred, I/the
                patient was entitled to private medical insurance benefits under
                my/the patient’s chosen laya healthcare scheme. I declare that
                my/the patient’s doctor recommended the specialist treatment and
                to the best of my knowledge and belief the information given on
                this form is true and complete. For the purpose of considering
                and determining the eligibility/ appropriateness of claims laya
                healthcare may request the
                hospital/specialist/consultant/physician/health provider
                concerned to furnish laya healthcare or its duly authorised
                agents acting on its behalf (including, but not limited to,
                medical professionals whose services are retained by laya
                healthcare) with all necessary information as laya healthcare or
                its authorised agents may seek in connection with any treatment
                or other services provided to you or your dependant(s). This
                includes copies of hospital/ medical records related to a claim
                made by you or your dependant(s), by which I mean the following
                in particular:
              </p>
              <ul>
                <li>records of physical or mental illness or ill-health;</li>
                <li>medical histories;</li>
                <li>records of treatments obtained by you;</li>
                <li>length of any stay in a hospital;</li>
                <li>discharge summaries;</li>
                <li>previous insurance details;</li>
                <li>
                  other treatments or services received by you or your
                  dependant(s);
                </li>
              </ul>
              <p>
                Charges not eligible for benefit remain my responsibility to
                settle directly with the hospital and doctors concerned. I
                direct and authorise that all medical expenses (paid out by laya
                healthcare) recovered from the third party responsible for my/
                the patient’s injuries shall be refunded by my solicitor
                directly to laya healthcare. I further direct my solicitor to
                deduct these amounts from my settlement cheque and reimburse
                laya healthcare directly. In the event that medical expenses
                recovered from the third party are refunded directly to me, the
                member, I agree to refund these monies directly to laya
                healthcare.
              </p>

              <div>
                <Checkbox
                  checked={!!consentChecked}
                  color="primary"
                  required={true}
                  onChange={(e) =>
                    handleInputChange(
                      "confirmInfo",
                      "consentChecked",
                      e.target.checked ? "yes" : ""
                    )
                  }
                />
                I Agree
              </div>
              <div className="my-3">
                {isSigPad ? null : (
                  <>
                    <DCSignature />
                    {errors.signUrl ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          color: "red",
                        }}
                      >
                        {errors.signUrl}
                      </div>
                    ) : null}
                  </>
                )}
              </div>

              {isHospitalName === "mymedical" && (
                <div className="my-3">
                  <p>
                    Medosync Ltd. is a data processor of MyMedical and
                    submitting the claim on behalf of MyMedical.
                  </p>
                  <p>
                    MedoSync Ltd.{" "}
                    <a href="https://www.medosync.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </a>
                  </p>
                  <p>
                    MyMedial Ltd. Privacy Policy can be found on display in the
                    Clinic.
                  </p>
                </div>
              )}
              {isHospitalName === "affidea" && (
                <div className="my-3">
                  <p>
                    Medosync Ltd. is a data processor of Affidea and submitting
                    the claim on behalf of Affidea.
                  </p>
                  <p>
                    MedoSync Ltd.{" "}
                    <a href="https://www.medosync.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </a>
                  </p>
                  <p>
                    Affidea Ltd.{" "}
                    <a href="https://www.affidea.ie/privacy-policy/" target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </a>
                  </p>
                </div>
              )}
            </div>
            <div className="my-3">
              <span style={{ display: "flex", justifyContent: "flex-end" }}>
                <div style={{ textAlignLast: "end" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<Icon>send</Icon>}
                    className="my-3"
                    // type="button"
                    type={"submit"}
                    // onClick={() => props.history.push(`/sig/recapture/ts/${pid}`)}
                  >
                    Next
                  </Button>
                </div>
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state.confirmInfo,
});

export default connect(mapStateToProps, { handleInputChange, setKeyAction })(
  SignatureRecapture
);
