import React, { DetailedHTMLProps } from "react";

const options = [
  [1489, "Carlow"],
  [1488, "Cavan"],
  [1487, "Clare"],
  [1486, "Cork"],
  [1485, "Donegal"],
  [1484, "Co. Dublin"],
  [4148, "Dublin 1"],
  [4157, "Dublin 10"],
  [4158, "Dublin 11"],
  [4159, "Dublin 12"],
  [4160, "Dublin 13"],
  [4161, "Dublin 14"],
  [4162, "Dublin 15"],
  [4163, "Dublin 16"],
  [4164, "Dublin 17"],
  [4165, "Dublin 18"],
  [4149, "Dublin 2"],
  [4166, "Dublin 20"],
  [4167, "Dublin 22"],
  [4168, "Dublin 24"],
  [4150, "Dublin 3"],
  [4151, "Dublin 4"],
  [4152, "Dublin 5"],
  [4153, "Dublin 6/6W"],
  [4154, "Dublin 7"],
  [4155, "Dublin 8"],
  [4156, "Dublin 9"],
  [1483, "Galway"],
  [1482, "Kerry"],
  [1481, "Kildare"],
  [1480, "Kilkenny"],
  [1479, "Laois"],
  [1478, "Leitrim"],
  [1477, "Limerick"],
  [1476, "Longford"],
  [1475, "Louth"],
  [1474, "Mayo"],
  [1473, "Meath"],
  [1472, "Monaghan"],
  [1471, "Offaly"],
  [1470, "Roscommon"],
  [1469, "Sligo"],
  [1468, "Tipperary"],
  [1775, "Ulster"],
  [1467, "Waterford"],
  [1466, "Westmeath"],
  [1465, "Wexford"],
  [1464, "Wicklow"],
];

export const StateSelect: React.FC<
  DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >
> = (props) => {
  return (
    <select {...props}>
      {options.map(([v, label]) => (
        <option key={v} value={v}>
          {label}
        </option>
      ))}
    </select>
  );
};
