import { useEffect } from "react";
import { useLocation, useParams } from "react-router";
import {
  useGetQRbyTokenUuidQuery,
  useGetMmPatientLazyQuery,
} from "../generated/graphql";

export const usePatientAutofillFromUrl = (setPatientById: (p: any) => void) => {
  const location = useLocation();
  const { token } = useParams<{ token?: string }>();

  const { data } = useGetQRbyTokenUuidQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: { token: token || "" },
    context: { passAuthHeader: true },
  });
  const pid = data?.getQRbyTokenUUID?.pid;

  const [patientQuery] = useGetMmPatientLazyQuery({
    onCompleted: (data) => {
      setPatientById(data.getMMPatient);
    },
  });

  useEffect(() => {
    if (location.pathname.includes("/QR/") && token) {
      patientQuery({
        variables: { patientid: pid || 0 },
      });
    }
  }, [patientQuery, pid, token, location.pathname]);
};
