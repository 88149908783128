export function remove_linebreaks(str) {
  return str.replace(/[\r\n]+/gm, ",");
}

export function remove_linebreaks2(str) {
  if (str === null) {
    return null;
  }
  if (str !== null) {
    return str.replace(/[\r\n]+/gm, ",");
  }
}
