import {
  HANDLE_INPUT_CHANGE_PATIENT,
  SET_USER,
  SET_TERMINAL,
  LOGOUT,
  SUCCESS_NOTIFICATION,
  HANDLE_INPUT_CHANGE_GP,
  SET_KEY,
} from "./types";
import { StorageService } from "./StorageService";

export const setKeyAction = (key, value) => ({
  type: SET_KEY,
  payload: {
    key,
    value,
  },
});

export const handleInputChange = (section, key, value) => ({
  type: HANDLE_INPUT_CHANGE_PATIENT,
  payload: {
    section,
    key,
    value,
  },
});

export const handleGPInputChange = (section, key, value) => ({
  type: HANDLE_INPUT_CHANGE_GP,
  payload: {
    section,
    key,
    value,
  },
});

export const setUser = (user) => {
  StorageService.setUser(user);
  return {
    type: SET_USER,
    payload: { user },
  };
};

export const setTerminal = (terminal) => {
  StorageService.setTerminal(terminal);
  return {
    type: SET_TERMINAL,
    payload: { terminal },
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const successNotification = (isActive, message) => {
  return {
    type: SUCCESS_NOTIFICATION,
    payload: { isActive, message },
  };
};
