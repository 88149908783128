import React from "react";
import DataTable from "react-data-table-component";
import axios from "../config";
import {
  convertUnixDate,
  convertUnixDate2 /*,convertUnixDate3*/,
} from "../lib/dateConverter";
import InfiniteScroll from "react-infinite-scroller";
import Header from "../components/UI/Header/Header";
import ConfirmationModal from "../components/Modals/ConfirmationModal";

const ExpandedComponent = ({ data }) => (
  <pre>{JSON.stringify(data, null, 2)}</pre>
);

export default class ConsultantSignature extends React.Component {
  conditionalCellStyles = (column) => {
    return [
      {
        when: (row) => typeof row[column] === "boolean" && row[column],
        style: {
          backgroundColor: "rgba(63, 195, 128, 0.9)",
          color: "white",
        },
      },
      {
        when: (row) => typeof row[column] === "boolean" && !row[column],
        style: {
          backgroundColor: "rgba(242, 38, 19, 0.9)",
          color: "white",
        },
      },
    ];
  };
  onStampClick = (doctorId, patientId) => {
    const graphqlQuery = {
      query: `mutation {
                signTreatment(
                        patientId: ${patientId},
                        doctorId: ${doctorId}
                        )
                    }`,
    };
    return axios
      .post("", graphqlQuery)
      .then((res) => {
        console.log(res.data.data);
        const data = res.data.data;
        if (data) {
          let arr = [...this.state.patients];
          arr = arr.filter((v) => {
            return patientId !== v.id;
          });
          this.setState({ patients: arr });
        }
      })
      .catch((error) => {
        // console.log(error)
      });
  };

  generateColumns = (fields) => {
    return fields.map((f) => ({
      name: f.name,
      selector: f.field,
      sortable: f.sortable ? true : false,
      conditionalCellStyles: this.conditionalCellStyles(f.field),
      format: (row) => (f.format ? f.format(row, f.field) : row[f.field]),
      width: f.width,
      // cell: (row) => f.cell ? f.cell(row, f.field) : row[f.field]
      // format: (row) => { console.log(row) }
    }));
  };

  columns = this.generateColumns([
    {
      field: "ts_Admit_Date",
      name: "Admit Date",
      sortable: true,
      width: "8rem",
      format: (row, col) => (
        <div className="text-wrap">{convertUnixDate2(row[col])}</div>
      ),
    },
    {
      field: "ts_Discharge_Date",
      name: "Discharge Date",
      sortable: true,
      width: "8rem",
      format: (row, col) => (
        <div className="text-wrap">{convertUnixDate2(row[col])}</div>
      ),
    },
    {
      field: "Patient_Forenames",
      name: "Patient First Name",
      sortable: true,
      width: "8rem",
      format: (row, col) => <div className="text-wrap">{row[col]}</div>,
    },
    {
      field: "Patient_Surname",
      name: "Patient Surname",
      sortable: true,
      width: "8rem",
      format: (row, col) => <div className="text-wrap">{row[col]}</div>,
    },
    {
      field: "DOB",
      name: "DOB",
      sortable: true,
      width: "8rem",
      format: (row, col) => (
        <div className="text-wrap">{convertUnixDate(row[col])}</div>
      ),
    },
    {
      field: "MRN",
      name: "MRN",
      sortable: true,
      width: "8rem",
      format: (row, col) => <div className="text-wrap">{row[col]}</div>,
    },
    {
      field: "Health_Membership_Number",
      name: "Membership NO.",
      sortable: true,
      width: "10rem",
    },

    {
      field: "treatmentsAndDoctorsForInvoice",
      name: "Doctor Name",
      format: (row, col) => {
        return (
          <div className="d-flex flex-column">
            {row[col].map((v, i) => {
              return (
                <div
                  key={i}
                  className="cst-treatment-and-doctor text-wrap"
                >{`${v?.doctor?.User_Doctor_Consultant_Name}`}</div>
              );
            })}
          </div>
        );
      },
      width: "10rem",
    },
    {
      field: "treatmentsAndDoctorsForInvoice",
      name: "Treatment Provided",
      format: (row, col) => {
        return (
          <div className="d-flex flex-column">
            {row[col].map((v, i) => {
              return (
                <div
                  key={i}
                  className="w-100 cst-treatment-and-doctor text-wrap"
                >{`${v?.treatment?.Treatment}`}</div>
              );
            })}
          </div>
        );
      },
      width: "10rem",
    },
    {
      //color hack to space the two buttons
      field: "treatmentsAndDoctorsForInvoice",
      name: "Approve/Sign or Reject Treatments",
      format: (row) => {
        return (
          <div className="w-100 cst-treatment-and-doctor">
            <button
              className="btn  btn-sm btn-outline-danger"
              onClick={() =>
                this.setState({ patientId: row.id }, () => {
                  window.$("#comfirmation-modal").modal("show");
                })
              }
            >
              Reject Treatment
            </button>
            <font color="#fafafa">....</font>

            <button
              className="btn btn-sm btn-primary"
              onClick={() =>
                this.onStampClick(this.state.user.ClinicianId, row.id)
              }
            >
              Sign Treatments
            </button>
          </div>
        );
      },
    },
  ]);

  state = {
    user: JSON.parse(localStorage.getItem("user")),
    loading: true,
    patients: [],
    offset: 0,
    hasMore: false,
    perPage: 10,
    patientId: -1,
    isAdmited: true,
  };
  // patientsbyoffset(offset:${this.state.offset}, perPage:${this.state.perPage}, isAdmited:${this.state.isAdmited} ) {
  // getMyPatientsbyoffset(offset:${this.state.offset}, perPage:${this.state.perPage}) {
  //getDoctorPatientsByOffset(offset:${this.state.offset}, perPage:${this.state.perPage}) {
  fetchPatients = () => {
    // const token = localStorage.getItem('user');
    const graphqlQuery = {
      query: `query {
                doctorPatientsByOffset(offset:${this.state.offset}, perPage:${this.state.perPage}, isAdmited: ${this.state.isAdmited}) {
                    patients {
                        id
                        uuid
                        name
                        Health_Insurance_Company
                        Health_Membership_Number
                        MRN
                        MM_Invoice_Number
                        Patient_Title
                        Patient_Forenames
                        Patient_Surname
                        DOB
                        Address
                        Phone_Number
                        GPConsentYesNo
                        GP_PracticeName
                        NextOfKin
                        NextOfKinPhoneNumber
                        email
                        Did_Patient_Elect_To_Be_Private_YesNo
                        fe_patient_confirmed_any_positive_YesNo
                        ts_Symptoms_First_Noticed
                        ts_Doctor_First_Consulted_MedHistory
                        Previously_Claimed_For_Illness_YesNo
                        ts_Date_When_Claimed_For_This_Illness_Before
                        Name_Of_Doctor_Fisrt_Attended_Referral
                        ts_Date_Of_Doctor_First_Attended_Referral
                        Address_Of_Doctor_First_Attended_Referral
                        Admission_IsResult_Of_Accident_YesNo
                        ts_Date_of_Accident
                        Where_Did_Accident_Injury_Occur
                        How_Did_Accident_Injury_Occur
                        Was_Accident_Injury_Fault_of_Another_Party_YesNo
                        Contact_Information_of_Responsible_Party
                        Responsible_Party_Insurance_Company_Information
                        Are_You_Claiming_Expenses_Via_PIAB_YesNo
                        Are_You_Claiming_Expenses_Via_Solicitor_YesNo
                        Name_Address_of_Solicitor_If_Applicable
                        Agreed_to_Declaration_Consent
                        Agreed_to_Dataprotection
                        Agreed_to_MedoSync_Information_Processing
                        PatientPaidByCashOrCard
                        Patient_Signature
                        ts_Date_Patient_Signature
                        ts_Date_Patient_Registered
                        ts_Admit_Date
                        ts_Discharge_Date
                        ts_SMS_Sent
                        rejectedByConsultant_True_False
                        rejectedByConsultant_Reason
                        fever_or_Chills_YesNo
                        cough_YesNo
                        shortness_of_breath_YesNo
                        smell
                        flu_like_symptoms_YesNo
                        exposed_to_confirmed_Covid19_case_YesNo
                        Travel_abroad_last_two_weeks_YesNo
                        Worked_In_Covid19_Healthcare_facility_abroad_YesNo
                        treatmentsAndDoctorsForInvoice {
                          treatment {
                            id
                            uuid
                            Cost
                            Treatment
                            Hospital_Name
                            Treatment_Type
                          }
                          doctor {
                            id
                            uuid
                            name
                            accesslevel
                            User_Doctor_Consultant_Name
                            Medical_Council_Number
                            Laya_Healthcare_Doctor_Consultant_Code
                            Participating_Benefit_YesNo
                            User_Doctor_Consultant_Signature
                            Clinician_Specialty
                            ts_Sign_Date
                          }
                        }
                      }
                    }
                  }                  
                `,
    };
    axios
      .post("", graphqlQuery)
      .then((res) => {
        const data = res.data.data;

        if (data?.doctorPatientsByOffset.patients) {
          let newPatients = data.doctorPatientsByOffset.patients;
          let filteredPatients = newPatients.filter((v) => {
            // return v?.ts_Admit_Date?.length > 0

            if (
              (v.rejectedByConsultant_True_False === null ||
                v.rejectedByConsultant_True_False === "false") &&
              v.ts_Discharge_Date &&
              v.treatmentsAndDoctorsForInvoice.length
            )
              return v;
            return null;
          });
          let concatPatients = [...this.state.patients, ...filteredPatients];

          this.setState({
            patients: concatPatients,
            loading: false,
            offset: this.state.offset + this.state.perPage,
            hasMore: newPatients.length === this.state.perPage ? true : false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  //         }).then((res) => {
  //             console.log('done loading doctorPatientsByOffset', res.data.data)
  //             const data = res.data.data
  //             if (data?.doctorPatientsByOffset) {
  //                 let newPatients = data["doctorPatientsByOffset"];
  //                 let filteredPatients = newPatients.filter((v, i) => {
  //                     if ((v.rejectedByConsultant_True_False === null || v.rejectedByConsultant_True_False === "false") && v.ts_Doctor_Consultant_Signature === null && v.ts_Discharge_Date && v.treatmentsAndDoctorsForInvoice.length)
  //                         return v;
  //                     return null;
  //                 })
  //                 let concatPatients = [...this.state.patients, ...filteredPatients];
  //                 // console.log(this.state.offset, newPatients.length, this.state.perPage)
  //                 console.log('calling setState')
  //                 this.setState({
  //                     patients: concatPatients,
  //                     loading: false,
  //                     offset: this.state.offset + this.state.perPage,
  //                     hasMore: newPatients.length === this.state.perPage ? true : false
  //                 });
  //             }
  //         })
  //         .catch((error) => {
  //             this.setState({ loading: false });
  //              console.log(error)
  //         });
  // }

  rejectPatient = (patientId, message) => {
    let index = this.state.patients.findIndex((v) => v.id === patientId);
    console.log("findIndex", index);
    const graphqlQuery = {
      query: `
            mutation {
				updatePatient(
				  id: ${patientId}
				  update: { 
                    rejectedByConsultant_True_False:"true"
                    rejectedByConsultant_Reason:"${message}"
				  }
				)
			  }
        `,
    };
    //console.log(graphqlQuery.query)
    axios
      .post("", graphqlQuery)
      .then((res) => {
        if (res.data) {
          let patients = [...this.state.patients];
          patients[index]["rejectedByConsultant_True_False"] = "true";
          let filteredPatients = patients.filter((v, i) => {
            if (
              (v.rejectedByConsultant_True_False === null ||
                v.rejectedByConsultant_True_False === "false") &&
              v.ts_Doctor_Consultant_Signature === null &&
              v.ts_Discharge_Date &&
              v.treatmentsAndDoctorsForInvoice.length
            )
              return v;
            return null;
          });
          this.setState({ patients: filteredPatients });
        }
      })
      .catch((error) => {
        //    console.log(error)
      });
  };
  componentDidMount() {
    this.fetchPatients();
  }

  render() {
    console.log("state: ", this.state);
    return (
      <div className="container-fluid">
        <Header>
          {/* <button className="btn btn-outline-primary btn-sm mr-2" onClick={() => {
                        this.props.history.replace({
                            pathname: "/fetch-claims"
                        })
                    }} >View Claims</button>


                    <button className="btn btn-sm btn-outline-primary" onClick={() => {
                        this.props.history.replace({
                            pathname: "/fetch-patients"
                        })
                    }} >Edit Treatments</button> */}
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={() => {
              for (const row of this.state.patients || []) {
                this.onStampClick(this.state.user.ClinicianId, row.id);
              }
            }}
          >
            Sign all in view
          </button>
        </Header>

        <ConfirmationModal
          patientId={this.state.patientId}
          rejectPatient={this.rejectPatient}
        />
        <InfiniteScroll
          pageStart={0}
          loadMore={this.fetchPatients}
          hasMore={this.state.hasMore}
          loader={
            <div key={0}>
              <font color="blue">Loading ...</font>
            </div>
          }
        >
          <DataTable
            expandableRows
            expandableRowsComponent={<ExpandedComponent />}
            title="Consultant Signature"
            columns={this.columns}
            className="table table-responsive"
            overflowY={true}
            striped={true}
            noDataComponent={<div>Nothing to display!</div>}
            progressPending={this.state.loading}
            progressComponent={
              <div key={0} className="text-center">
                {" "}
                <font color="blue">Loading ...</font>
              </div>
            }
            data={this.state.patients}
            customStyles={{
              headCells: {
                style: {
                  fontWeight: 600,
                },
              },
            }}
          />
        </InfiniteScroll>
        {/* <div className="d-flex justify-content-end">
                    <button className="btn btn-sm btn-outline-primary" onClick={() => {
                        this.props.history.replace({
                            pathname: "/fetch-patients"
                        })
                    }} >Edit Treatments</button>
                </div> */}
      </div>
    );
  }
}
